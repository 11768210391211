
h1 {
    font-size: 66px  ;
    font-weight: 700;
    line-height: 90px;
  
    
  }
  
  
  h2 {
    font-size: 43px ;
    font-weight: 700; 
    
  }
  
  h3 {
    font-size: 24px ;
    font-weight: 700 ;
  
    
  }
  
  
  h4 {
    font-size: 20px ;
    font-weight: 500 ;
  }
  
  
  
  h5 {
    font-size: 17px ;
    font-weight: 500 ;
  }
  
  
  h6 {
    font-size: 16px ;
    font-weight: 400 ;
    
  }
  
  p{
    font-size: 14px ;
    font-weight: 400 ;
  }
  a{
    text-decoration: none;
  }

  .nav-link{
    color:'#000B19';
    font-weight: 700;
    font-size: 17px;
  }

  .nav-link:hover, .active {
color: #013F90 !important;
font-weight: 800 !important;
  }
  

  .border-line{
    width:170px;
    margin:10px 0;
    border:1px solid #ffffff;
  }

  .footer-text{
    font-size: 30px;
    font-weight: 700;
  }

.btn{
    font-size: 16px;
    border-radius: 0px;
    padding: 16px;
    font-weight: 700;
}
  .btn-primary{
    background-color: rgba(3, 127, 9, 1);
    color:#E6F1FF;
    border-color: rgba(3, 127, 9, 1);
  }

  .btn-primary:hover, .btn-primary:focus{
    color: rgba(3, 127, 9, 1);
    background-color:#E6F1FF ;
    border-color: rgba(3, 127, 9, 1);
  }


  
  .content-container {
    z-index: 2;
    position: relative;
  }

  .services .btn{
    border:1px solid #ffffff;
    color:#ffffff;
    background-color: #013F90;
    font-size: 24px;
    padding: 5px;
  }

  .info-card{
    position: absolute;
    top: 15%;
    right:5%;
  
  }
  .about-img-position {
    top: 8%; 
    left: 7%; 
   
    z-index: 1;
    opacity: 0.5; 
  }
.offer-section{
  padding: 0px 90px 30px 100px;
}
  .about-us-section{
    padding: 90px 90px 90px 100px;
  }
  .authority{
    height: 80px !important;
  }
  @media (max-width: 1200px) {
   
    .info-card{
        position: absolute;
        top: 15%;
        right:0%;
      
      }
      .about-us-section{
        padding: 60px;
      }
    
  }
  @media (max-width: 992px) {
    
    h1 {
        font-size: 40px;
        line-height: 60px;
      }

      .about-us-section{
        padding: 60px;
      }

      .info-card{
        position: relative;
        top:    0%;
        right:0%;
      
      }
   
  }
  @media (max-width: 768px) {

    h1 {
      font-size: 32px;
      line-height: 50px;
    }
  
    h2 {
      font-size: 24px; 
    }
    
    h3 {
      font-size: 20px;
     
    }
    h4 {
      font-size: 16px;
    }
    h5 {
      font-size: 15px;
    }
    h6{
      font-size: 14px;
    }
    p{
      font-size: 11px;
    }
    .nav-link{
       
        font-size: 14px;
      }
      .btn{
        font-size: 14px;
    }
    .about-us-section{
        padding: 17px 20px;
      }

    .offer-section{
      padding: 0px 20px;
    }

      .about-img-position {
        top: 3.5%; 
        left: 76%;
     
       
       
      }
      .footer-text{
        font-size: 12px;
        font-weight: 700;
      }
      .authority{
        height: 35px !important;
      }
     
  }

  @media (max-width: 375px) {

    .footer-text{
      font-size: 11px;
      font-weight: 700;
    }
    ul{
   
      list-style: none; 
    }
   

}