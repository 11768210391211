
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');


code {
  font-family: 'Mail Sans Roman', sans-serif;
  
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
  
}

body {
margin: 0;
background-color: #ffffff !important;
color: #000000 !important;
font-family:  'Ubuntu', sans-serif;
overflow-x: hidden !important;
position: relative;



}